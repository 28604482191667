<template>
    <div>
        <div>
            <h3 class="headline">{{$t('data.formule_utiilisation_du')}} <span class="font-weight-bold" style="color: #f5365c;">Maisonier</span></h3><span class="line margin-bottom-45"></span>
            <div class="clearfix"></div> <br> <br>
        </div>
        <b-row v-if="entreprises.length >= 1">
					<b-col  xs="12" md="4" v-for="entreprise in entreprises" :key="entreprise.idEntreprise"> 
						<div>
                            <div class="plan-price">
                                <h3>{{ formulesPaiement[0].type }}</h3><span class="value">{{ formulesPaiement[0].prix }}</span><span class="plan-currency">F</span> <span class="period">/ {{$t('data.utilisation_par_an')}}</span>
                            </div>
                            <div class="plan-features">
                                <ul class="pl-0">
                                    <li  v-for="(acces,i) in formulesPaiement[0].access" :key="i">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" color="green" class="bi bi-check" viewBox="0 0 16 16">
                                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                        </svg>
                                        {{ acces }}
                                    </li>
                                </ul> <a class="button" href="#" @click.prevent="callSuscribeToFormuleForm(formulesPaiement[0])">{{$t("data.souscrire_a_un_pack")}}</a>
                            </div>
                        </div>
					</b-col>
					<b-col  xs="12" md="4"> 
						<div class="card">
							<div class="text-center">
								<div class="card-body pb-0">
									<b-avatar text="+" size="8em" />
								</div>
								<div class="card-body mt-0">
									<h4 class="truncate card-title blue">Nouvelle entreprise</h4>
									<h6 class="truncate card-subtitle text-muted"></h6>
									<b-button block variant="danger" class="mt-3" @click.prevent="$bvModal.show('add-enterprise')">Ajouter une entreprise</b-button>
								</div>
							</div>
						</div>
					</b-col>
        </b-row>



        <b-row>
            <b-col lg="4" md="6" sm="12"  class="plan four"  v-for="formule in formulesPaiement" :key="formule.idFormule">
                <div>
                    <div class="plan-price">
                        <h3>{{ formule.type }}</h3><span class="value">{{ formule.prix }}</span><span class="plan-currency">F</span> <span class="period">/ {{$t('data.utilisation_par_an')}}</span>
                    </div>
                    <div class="plan-features">
                        <ul class="pl-0">
                            <li  v-for="(acces,i) in formule.access" :key="i">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" color="green" class="bi bi-check" viewBox="0 0 16 16">
                                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                                </svg>
                                {{ acces }}
                            </li>
                        </ul> <a class="button" href="#" @click.prevent="callSuscribeToFormuleForm(formule)">{{$t("data.souscrire_a_un_pack")}}</a>
                    </div>
                </div>
            </b-col>
        </b-row>

        <!--MODAL DE PAIEMENT -->
        <b-modal id="modal-payment" hide-footer no-close-on-backdrop>
            <template #modal-title>
                <span class="font-weight-bold">{{$t('data.souscrire_au_forfait')}}</span><code> {{ formuleType }}</code>
            </template>
            <div class="d-block text-center">
              <b-row>
                  <b-col>{{$t('data.souscrire_au_forfait_facturation')}}</b-col>
                  <b-col cols="7">
                    <b-form-group label="" class="position-relative">
                        <div class="mb-1 text-left font-weight-bold h6"> {{ placeholderDtaFacturation }} </div>
                        <a href="" v-b-tooltip.top="$t('data.detail_habitant_modifier')" @click.prevent="goToProfile" class="setPosition"><i class="fas fa-edit" style="cursor:pointer; font-size: 24px"></i></a>
                    </b-form-group>
                  </b-col>
             </b-row>
             <b-row class="mb-5 mt-1">
                  <b-col>{{$t('data.souscrire_au_forfait_type')}}</b-col>
                  <b-col cols="7">
                        <v-select label="libelle" :options="typesForfaits" v-model="typeF" :class="{ disabled: disabled == true }"></v-select>
                  </b-col>
             </b-row>
             <b-row style="margin-top: 20px">
                  <b-col>{{$t('data.souscrire_au_forfait_moyen_paiement')}}</b-col>
                  <b-col cols="7">
                        <v-select label="title" :options="modePaiements" v-model="modeP" :reduce="(modePaiement) => modePaiement.moyen"  @input="setPaypalButton"></v-select>
                        <div class="help-block font-weight-bold mt-2">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="rounded overflow-hidden" style="width: 36px; height: 36px;">
                                    <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/mtn_mobileMoney.png`" alt="Amex">
                                </div>
                                
                                <div class="rounded overflow-hidden" style="width: 36px; height: 36px;">
                                    <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/orange_money.jpg`" width="36" alt="Paypal" border="0">
                                </div>
                                <div class="rounded overflow-hidden" style="width: 36px; height: 36px;">
                                    <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/carte_visa.png`" width="36" alt="Visa" border="0">
                                </div>
                                <div class="rounded overflow-hidden" style="width: 36px; height: 36px;">
                                    <img class="border-0 w-100 h-100" :src="`${$router.options.base}img/imgPayments/master_card.png`" width="36" alt="Mastercard" border="0">
                                </div>
                                <div class="rounded overflow-hidden" style="width: 36px; height: 36px;">
                                    <img class="border-0 w-100 h-100"  :src="`${$router.options.base}img/imgPayments/paypal.png`" width="36" alt="Direct debit" border="0">
                                </div>
                            </div>
                            <br>{{$t("data.souscrire_au_forfait_moyen_paiement_description")}}		
                        </div>
                  </b-col>
             </b-row>
             <b-row class="mt-2">
                  <b-col>{{$t('data.souscription_frequence')}}</b-col>
                  <b-col cols="7">
                        <b-form-radio-group
                            v-model="frequencePaiement"
                            :options="options"
                            class="mb-3"
                            value-field="montant"
                            text-field="nom"
                            disabled-field="notEnabled"
                        ></b-form-radio-group>
                  </b-col>
             </b-row>                  
            </div>
            <div v-if="showButton" ref="paypal" id="customizeButtonStyle"></div>
            <b-button v-if="!showButton" class="bouton-payer mt-3" block @click="$bvModal.hide('bv-modal-example')">{{$t('data.occupation_charge_payer')}}</b-button>
        </b-modal>
    </div>
</template>
<script>
    const php  = require ( 'phpjs' ); 
     export default {
        name: "Packs",
       data:()=>({
           showButton:false,
           loaded:false,
           showOverlay:false,
           disabled:false,
           formuleType:'',
           idFormule:'',
           frequencePaiement:'12000',
           detailsPaiements:'',
           typeF:'gratuit',
           modeP:'Gratuit',
           options: [
            { montant: '12000', nom: '12 000 F/ 3 mois' },
            { montant: '40000', nom: '40 000 F/ An' }
          ],
          modePaiements: [
            {
            title: 'Gratuit',
            icon: 'fa fa-database',
            moyen: 'bien vouloir choisir un mode de paiement'
            },
            {
            title: 'Paypal',
            icon: 'fa fa-database',
            moyen: 'paypal'
            },
            {
            title: 'MTN Mobile money',
            icon: 'fa fa-book',
            moyen: 'mntMoMo'
            },
            {
            title: '  Orange Money',
            icon: 'fas fa-dollar-sign',
            moyen: 'om'
            },
        ],
        typesForfaits: [
            {
            libelle:'choisissez un type',
            valeur:'gratuit',
            },
            {
            libelle:'SILVER (limité)',
            valeur:'SILVER',
            },
            {
            libelle:'GOLD (full access)',
            valeur:'GOLD',
            },
        ],
        formulesPaiement: [
            {
                idFormule:1,
                type: 'GRATUIT',
                prix:'0',
                access:[
                    'Limité à 02 locataires',
                    'Limité à 2 locations',
                    'Utilisateur Unique',
                    '02 Modèles de contrat',
                    'Limité à 1 lot',
                    'stats limités',
                    'Analyses et rentabilité limité'
                ],
            },
            {
                idFormule:2,
                type: 'SILVER',
                prix:'50000',
                access:[
                    'etat des lieu illimité',
                    'Limité à 2 locations',
                    'locataires illimmités',
                    'limité à 50 locations',
                    'utilisateur unique',
                    'stats illimités',
                    'Analyses et rentabilité limité'
                ],
            },
            {
                idFormule:3,
                type: 'GOLD',
                prix:'90000',
                access:[
                    'etat des lieu illimité',
                    'locataires illimmités',
                    'locations illimmités',
                    'Multi-utilisateur',
                    'Modèles de contrat illimité',
                    'stats illimités',
                    'Analyses et rentabilité illimité'
                ],
            },

        ],
       }),
     
        watch: {
        },
        computed: {
        userData() {
            return this.$store.state.user
        },
        placeholderDtaFacturation(){
            return this.$store.state.user.nom+' '+this.$store.state.user.prenom+', '+this.$store.state.user.tel
        },
    },
        methods:{
            setPaypalButton(){
                console.log('ecoute changement de valeur sélectionnée', this.modeP);
                if(this.modeP=='paypal'){
                    this.showButton=true;
                    this.toggleStatus();
                }else{
                    this.showButton=false
                }
            },
            goToProfile(){
                this.$router.push({name: 'profil', query: {target: "call-profile"}}) 
            },
            toggleStatus() {
                this.showOverlay=true

                const script = document.createElement("script");
                script.src ="https://www.paypal.com/sdk/js?client-id=AWll2noucpUtJT86ULiJnfJoXRSTXMNOCdg64upWlQrGR5sizlQsVcoaKJANLeobax6zT3w0C4DgCgrX";
                script.addEventListener("load", this.setLoaded);
                document.body.appendChild(script);

            },
            setLoaded: function() {
                this.loaded = true;
                window.paypal
                    .Buttons({
                    createOrder: (data, actions) => {
                        return actions.order.create({
                        purchase_units: [
                            {
                            description:"test",
                            amount: {
                                currency_code: "USD",
                                value: 22
                            }
                            }
                        ]
                        });
                    },
                    onApprove: async (data, actions) => {
                        const order = await actions.order.capture();
                        this.paidFor = true;
                        this.detailsPaiements=order
                        console.log(order);
                        //this.suscribeToFormule();
                        this.upgradeFormule();
                    },
                    onError: err => {
                        console.log(err);
                    }
                    })
                    .render(this.$refs.paypal);
                    this.showOverlay=false
            }, 
            /**
             * METHODE DE SOUSCRIPTION A UNE FORMULE
             */
            callSuscribeToFormuleForm(formule){
                console.log('formule ',formule);
                this.formuleType=formule.type
                this.idFormule=formule.idFormule
                this.typeF=formule.type;
                this.$bvModal.show('modal-payment')
            },
            suscribeToFormule(){
                this.showOverlay=true;
                let data={
                    typeFormule:this.typeF,
                    montant:this.frequencePaiement,
                    detailsPaiements:this.detailsPaiements
                }
                console.log('postage formulaire',data);

                axios.post("formules",data).then(response =>{
                    this.$bvModal.hide('modal-payment')
                    this.showOverlay=false;
                    return App.notifySuccess(response.message)
                })
                .catch(error => {
                    this.showOverlay=false;
                    return App.notifyError(error.message)
                });
            },

            //upgradee de formule
            upgradeFormule(){
                this.showOverlay=true;
                let data={
                    idFormule: this.idFormule
                }
                console.log('postage formulaire',data);

                axios.patch("entreprises/set-formule",data).then(response =>{
                    this.$bvModal.hide('modal-payment')
                    this.showOverlay=false;
                    return App.notifySuccess(response.message)
                })
                .catch(error => {
                    this.showOverlay=false;
                    return App.notifyError(error.message)
                });
            }
            
        },
        mounted(){
            //console.log('user data',this.userData.preferences.devise);
        },
    }
</script>
<style scoped>
.plan-price h3 {
    text-align: center;
    font-size: 20px;
    padding: 10px 0;
    margin-bottom: 25px;
    color: #606060;
    font-weight: 600;
    text-align: center;
    background-color: rgba(0, 0, 0, .02)
}

.plan-price {
    font-size: 36px;
    font-weight: 300;
    color: #606060;
    text-align: center;
    padding: 0 0 22px;
    position: relative
}

.plan .plan-price,
.plan a.button {
    background-color: #f6f6f6
}



.plan a.button {
    font-size: 18px !important;
    font-weight: bold;
}

.plan:hover .plan-price,
.plan:hover a.button {
    background-color: #f5365c;
    color: #fff;
}
.bouton-payer{
    background-color: #f5365c;
    border: none;
}

.plan:hover .plan-price h3,
.plan:hover .plan-price .value {
    color: #fff ;
}

.plan:nth-child(3) .plan-price,
.plan:nth-child(3) a.button {
    background-color: #f5365c;
    color: #fff;
}

.plan:nth-child(3) .plan-price h3,
.plan:nth-child(3) .plan-price .value {
    color: #fff ;
}






/* .plan.color-4 .plan-price,
.plan.color-4 a.button {
    background-color: #e54b81
} */

/* .plan.color-5 .plan-price,
.plan.color-5 a.button {
    background-color: #a558a6
} */

.plan-price .value {
    font-weight: 600;
    letter-spacing: -1px;
    color: #f5365c;
}

.plan-currency {
    font-size: 22px;
    opacity: .7;
    position: relative;
    margin: 0px 0px 0 3px;
    top: -15px;
    font-weight: 300;
}

.period {
    display: block;
    font-size: 16px;
    margin: 2px 0 0;
    opacity: .7
}

.plan-features {
    background: #fff;
    border-top: none;
    padding: 12px 0 0;
}

.plan-features ul li {
    padding: 8px 0;
    text-align: center
}

.plan-features a.button {
    position: relative;
    display: block;
    margin: 0 auto;
    margin: 12px 0;
    text-align: center;
    color: #666;
    padding: 10px 0;
    font-size: 14px;
    -webkit-transition: all .2s ease-in-out;
    -moz-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    -ms-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out
}

.color-2 .plan-price,
.color-3 .plan-price,
.color-4 .plan-price,
.color-5 .plan-price,
.color-2 .plan-price h3,
.color-3 .plan-price h3,
.color-4 .plan-price h3,
.color-5 .plan-price h3,
.color-2 .plan-price .plan-currency,
.color-3 .plan-price .plan-currency,
.color-4 .plan-price .plan-currency,
.color-5 .plan-price .plan-currency,
.color-2 .plan-features a.button,
.color-3 .plan-features a.button,
.color-4 .plan-features a.button,
.color-5 .plan-features a.button {
    color: #fff
}

.color-2 .plan-price h3,
.color-3 .plan-price h3,
.color-4 .plan-price h3,
.color-5 .plan-price h3 {
    background-color: rgba(0, 0, 0, .05)
}


.plan > div {
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
}

.button {
    margin-bottom: 100px
}
li{
    list-style-type: none;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 500;
}
.setPosition{
    position:absolute;
    top: -5px;
    right: 1px;
}
#customizeButtonStyle{
  max-width: 30%!important;
  margin: 0 auto;
}

</style>
